

.reelHeadDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reelHead {
    flex-grow: 1;
    text-align: center;
}

.addReelBtn{
    margin: 1rem;
}

.categoryFilters{
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.reelTable{
    height: 80vh;
}

.reelRow{
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    font-size: smaller;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.freeTag{
    padding: 5px;
    text-align: center;
    font-weight: bolder;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: lightgreen;
    color: green;
}

.paidTag{
    padding: 5px;
    text-align: center;
    font-weight: bolder;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: lightpink;
    color: red;
}

.freeButton{
    background-color: lightgreen;
    color: green;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.paidButton{
    background-color: lightpink;
    color: red;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.allButton{
    background-color: lightblue;
    color: blue;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}