.addPdf {
    margin: 3rem;
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mainHeadDiv {
    max-width: 800px;
    margin: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #333;
}

.mainHead {
    text-align: center;
    font-weight: 600;
    font-size: 1.75rem;
    color: #333;
    margin-bottom: 2rem;
}

.inputDiv {
    margin: 1rem 0;
}

.inputDiv label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
}

.input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    background-color: #fff;
    color: #000;
}

.checkDiv {
    display: flex;
    margin-top: 1rem;
    gap: 20px;
}

.saveBtnDiv {
    display: flex;
    justify-content: center;
}

.saveBtn {
    width: 60%;
    margin-top: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.saveBtn:hover {
    background-color: #0056b3;
}
