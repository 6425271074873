.container{
    padding: 20px;
    margin: 20px;
    background-color: #f7f9fc;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 90vh;
    overflow-y: auto;
}
.enableBarDiv{
    display: flex;
    gap: 1rem;
}
.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  