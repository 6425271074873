input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.gradientBtn{
    background: linear-gradient(90deg, #2c3c8d, #02aaec);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 50px 40px;
    cursor: pointer;
}

.gradientBtn:hover{
    background: linear-gradient(180deg, #02aaec, #2c3c8d);
}