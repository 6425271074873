
.header{
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #333;
}

.form{
    background-color: #e9e9e9;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 800px;
    margin-top: 3rem ;
}

.convertButton{
    margin-top: 1rem;
    display: flex;
    justify-content: end;   
}

.finishLine{
    height: 50px;
    width: 50px;
}

.modalBody{
    text-align: center;
}