.not-found-container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-attachment: fixed; 
    background-position: center;
    padding: 20px;
    overflow: hidden;
  }
  
  .not-found-content {
    max-width: 700px;
    text-align: center;
    padding: 50px;
    background: rgba(255, 255, 255, 0.85); 
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .error-icon {
    font-size: 4rem;
    color: #ff6b6b;
    margin-bottom: 20px;
    animation: rotateIn 1s ease-in-out;
  }
  
  @keyframes rotateIn {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  
  .error-code {
    font-size: 7rem;
    color: #ff6b6b;
    margin-bottom: 20px;
    text-shadow: 0 0 15px rgba(255, 107, 107, 0.5);
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px rgba(255, 107, 107, 0.8);
    }
    to {
      text-shadow: 0 0 25px rgba(255, 107, 107, 1);
    }
  }
  
  .not-found-content h2 {
    font-size: 2.8rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .not-found-content p {
    font-size: 1.3rem;
    margin-bottom: 40px;
    color: #555;
  }
  
  .go-home-btn {
    padding: 14px 30px;
    font-size: 1.3rem;
    background-color: #007bff;
    border: none;
    border-radius: 50px;
    box-shadow: 0 5px 20px rgba(0, 123, 255, 0.5);
    transition: all 0.4s ease;
  }
  
  .go-home-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 10px 30px rgba(0, 123, 255, 0.8);
    transform: translateY(-4px) scale(1.05);
  }
  
  @media (max-width: 768px) {
    .error-code {
      font-size: 5rem;
    }
  
    .go-home-btn {
      font-size: 1.1rem;
      padding: 12px 25px;
    }
  
    .not-found-content h2 {
      font-size: 2.2rem;
    }
  }
  