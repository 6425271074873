.coursesContainer {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    min-height: 90vh;
    overflow-y: auto;
    background-color: #f7f9fc;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
}

h1 {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 20px;
}

.searchInput {
    width: 100%;
    padding: 12px;
    margin-bottom: 25px;
    border: 2px solid #2980b9;
    border-radius: 8px;
    font-size: 16px;
    transition: border 0.3s ease;
}

.searchInput:focus {
    border-color: #3498db;
    outline: none;
}

.courseList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 60vh;
}

.courseItem {
    width: 90%;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
}

.courseItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
}

.courseItem h3 {
    margin: 0;
    color: #2c3e50;
    font-size: 22px;
}

.noResults {
    color: #e74c3c;
    font-size: 18px;
    margin-top: 20px;
}

.courseDetailItem {
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.courseDetailItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.courseDetailItem h4 {
    margin: 0;
    color: #2c3e50;
    font-size: 20px;
}
