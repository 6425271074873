.mainHeadDiv {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
  }
  
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #333;
}
  
  .mainHead {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .inputDiv {
    margin-bottom: 15px;
  }
  
  .inputDiv label {
    display: block;
    margin-bottom: 5px;
    color: #495057;
  }
  
  .checkDiv {
    display: flex;
    gap: 10px;
  }
  
  .addChapter{
    display: flex;
    justify-content: flex-end;
  }

  .addBtns {
    display: flex;
    justify-content: space-between;
}
