body{
  overflow: hidden;
}

.sidebarDiv{
    height: 100vh !important;
    overflow: hidden;
    display: flex;

}

.sidebar{
    height: 100vh !important;
    background: linear-gradient(90deg, #2c3c8d, #02aaec) !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

  .footer {
    margin-top: auto;
  }
  
  .OutletDiv {
    flex-grow: 1;
    overflow-y: auto;
  }

.menuBar{
    flex-grow: 0 !important;
    flex-grow: 1;
}

.link{
    text-decoration: none;
    color: white !important;
}

.item{
  display: flex;
  justify-content: space-between;
}

.item:hover{
    background: linear-gradient(90deg, #02aaec, #2c3c8d) !important;
    border-radius: 10px;
}

.activeItem{
    background: linear-gradient(90deg, #02aaec, #2c3c8d) !important;
    border-radius: 10px;
}

.userImageDiv {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50%; 
    overflow: hidden; 
    height: 100px;
}

.dropdown{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    background-color: #2c3c8d;
    margin: 10px;
    font-size: 12px;
}