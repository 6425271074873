.loginHead{
    display: flex;
    justify-content: center;    
    align-items: center;
    text-align: center;
}

.error{
    color: red;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
}