.page{
    overflow-y: scroll;
    height: 90vh;
    scrollbar-width: thin;
}

.courseName {
    text-align: center;
    font-weight: 700;
    font-family: 'Roboto', Arial, sans-serif; /* Example of another font family */
}


.notFoundDiv{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.mainToggle{
    width: 97%;
}

.accordianHeader{
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.accordianHeader:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    transform: scale(1.02) !important;
}


.item {
    margin-bottom: 1rem;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid;
}

.item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: none;
}

.options{
    display: flex;
    gap: 10px;
}

.header{
    display: flex;
    justify-content: space-between;
}

.topicOptions{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 1rem;
}

.itemNumber{
    width: 20px;
    height: 20px;
    background-color: #bebebe;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

.createQuestionBtn:hover{
    background-color: #34495e;
    color: rgb(255, 71, 163);
}

.cloneBtn:hover,
.cloneMt:hover{
    background-color: #34495e;
    color: rgb(197, 71, 255);
}

.addMt{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: #2c3e50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.icon {
    transition: transform 0.2s ease-in-out; /* Smooth transition for scaling */
    width: 20px;
    height: 20px;
  }

  .smallIcon {
    transition: transform 0.2s ease-in-out; /* Smooth transition for scaling */
    width: 22px;
    height: 22px;
  }

  .icon:hover,
  .smallIcon:hover {
    transform: scale(1.2); /* Scale up the icon to 120% on hover */
  }
  

.addBtnDiv{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.createQuestionBtn,
.deleteBtn,
.cloneBtn,
.editBtn,
.addBtn{
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #2c3e50;
    color: white;
}

.addBtn:hover,
.addMt:hover{
    background-color: #34495e;
    color: rgb(71, 151, 255);
}

.editBtn:hover,
.editMt:hover{ 
    background-color: #34495e;
    color: rgb(255, 212, 71);
}


.deleteBtn:hover,
.deleteMt:hover{
    background-color: #34495e;
    color: rgb(255, 71, 71);
}

.cloneMt,
.deleteMt,
.editMt{
    width: 25px;
    border: none;
    height: 25px;
    border-radius: 5px;
    background-color: #2c3e50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteAnimation{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.searchBar {
    width: 90%;
    padding: 12px;
    margin-bottom: 25px;
    border: 2px solid #2980b9;
    border-radius: 8px;
    font-size: 16px;
    transition: border 0.3s ease;
}

.searchBar:focus {
    border-color: #3498db;
    outline: none;
}

.dropdown {
    position: relative; 
}

.dropdownMenu {
    display: flex;
    flex-direction: column;
    position: absolute; 
    top: 100%; 
    left: 0; 
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    width: 150px;
    z-index: 1;
}

.newPdf {
    padding: 8px 12px;
    cursor: pointer;
    color: blue;
    text-decoration: none;
}

.newPdf:hover {
    background-color: lightgray;
}


.emptyStateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.emptyIcon {
    font-size: 60px;
    color: #fe9706;
    animation: bounceRotate 2s infinite;
}

@keyframes bounceRotate {
    0%, 100% {
        transform: translateY(0) rotate(0deg);
    }
    25% {
        transform: translateY(-10px) rotate(15deg);
    }
    50% {
        transform: translateY(0) rotate(0deg);
    }
    75% {
        transform: translateY(-10px) rotate(-15deg);
    }
}

/* styles.module.css */
.slNoInputField {
    width: 60px; /* Set appropriate width for dropdown */
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* outline: none; */
    box-sizing: border-box;
}

.slNoInputField:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

.updateSlNo{
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    background-color: #ccc;
    color: #333;
    cursor: pointer;
}

.updateSlNo.clicked {
    /* Clicked button styles */
    background-color: #4CAF50; /* Change this to any color you prefer */
    color: #fff;
}

.materialSearchBar {
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-family: inherit;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.materialSearchBar:focus {
    border-color: #007bff; /* Primary color for focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle blue glow */
}

.materialSearchBar::placeholder {
    color: #aaa;
    opacity: 1; /* Ensures full opacity for the placeholder */
}

.materialSearchBar:hover {
    border-color: #bbb; /* Slightly darker border on hover */
}

.NoData{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.flipContainer {
    perspective: 1000px; /* Creates a 3D space for the flip effect */
    display: inline-block;
    text-align: center;
}

.flipIcon {
    display: inline-block;
    animation: horizontalFlip 2s ease-in-out infinite; /* Duration of the animation */
    transform-origin: center;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Default shadow */
}

@keyframes horizontalFlip {
    0% {
        transform: rotateY(0deg);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    }
    25% {
        transform: rotateY(90deg);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Shadow during flip */
    }
    50% {
        transform: rotateY(180deg);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Shadow during flip */
    }
    75% {
        transform: rotateY(90deg);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Shadow during flip */
    }
    100% {
        transform: rotateY(0deg);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Return to default shadow */
    }
}

.statusInProgress{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 5px;
    background-color: #fe9706;
    border-radius: 5px;
    font-size: small;
}

.statusPending{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 5px;
    background-color: #969393;
    color: #fff;
    font-size: small;
    border-radius: 5px;

}

.status{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 5px;
    background-color: #05802c;
    color: #fff;
    font-size: small;
    border-radius: 5px;
}

