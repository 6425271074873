.ebook{
    max-height: 95vh;
    overflow-y: auto;
    scrollbar-width: none;
}
.ebookHead{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ebookButtons{
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


.button{
    margin: 1rem;
}

.table{
    text-align: center;
    margin-top: 2rem;
}

.tableData {
    max-width: 300px;
    overflow-wrap: break-word; /* Allows long words to be broken and wrapped onto the next line */
}

.addButton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.navItem {
    background: linear-gradient(180deg, #02aaec, #2c3c8d) !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: background 0.9s ease, transform 0.9s ease, box-shadow 0.9s ease, opacity 0.9s ease; /* Smooth transitions */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for better depth */
    opacity: 1; /* Ensure opacity is 1 by default */
}

.navItem:hover,
.activeNavItem {
    background: linear-gradient(180deg, #2c3c8d, #02aaec) !important; /* Vibrant gradient for hover */
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transform: scale(1.5); /* Slightly enlarge the element */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
    opacity: 1; /* Ensure opacity is 1 on hover */
}


