/* Home.css */

.homeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh; /* Full viewport height */
    background-color: #f7f9fc; /* Light background color */
    font-family: Arial, sans-serif;
  }
  
  .homeContent {
    font-size: 3rem; /* Large font size for "Admin Panel" */
    color: #333; /* Dark text color */
    padding: 20px; /* Padding around the text */
    border: 2px solid #007bff; /* Blue border */
    border-radius: 8px; /* Rounded corners */
    background-color: #fff; /* White background for the content */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center-align the text */
  }
  