.app {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }
  
  .app .mainHeading  {
    text-align: center;
  }
  .form{
    padding: 5px;
  }
  .form-container {
  position: relative; /* Ensure the container is positioned */
}

.form-container form {
  /* Example to position form at top left */
  position: absolute;
  top: 0;
  left: 0;
}
