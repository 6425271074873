.inputDiv{
    margin-bottom: 1rem;
}

.chapterRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.questionRow{
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.placeEnd{
    display: flex;
    justify-content: flex-end;
}